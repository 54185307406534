.sidebar {
    background: $lightest-gray;
    order: 1;
    position: relative;

    .caption {
        padding: 20px 25px 0;
        text-align: center;

        .caption-text {
            color: $dark-gray;
            margin-right: 20px;
        }
    }

    .parties-placeholder {
        display: none;
        height: 62px;
        width: 100%;
        margin-top: 3px;
    }

    .sidebar-content {
        background: white;
    }

    .loader-wrapper {
        color: $dark-gray;

        > * {
            vertical-align: middle;
        }

        .loader {
            height: 15px;
            margin-right: 5px;
            vertical-align: middle;
            width: 15px;
        }
    }
}

@media only screen and (min-width: $screen-sm) {

    .sidebar {
        box-sizing: border-box;
        min-width: 30%;
        max-width: 450px;
        order: 2;

        .caption {
            text-align: right;
        }

        .parties-placeholder {
            display: block;
        }
    }
}
