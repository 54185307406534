.parties-group {
    background: $lightest-gray;
    padding: 10px 25px 10px;
    position: relative;

    &.collapsible {

        .collapse-toggle {
            cursor: pointer;
            display: initial;
        }

        .parties-overlay {
            background: $orange;
        }
    }

    &.expanded {
        z-index: 1;

        .parties-content {
            display: block;
        }
    }

    &.hide-on-mobile {
        display: none;
    }

    .collapse-toggle {
        color: $orange;
        cursor: pointer;
        position: absolute;
        right: 25px;
        top: 9px;

        &.collapse {
            top: 11px;

            .toggle-icon {
                font-size: 1.3em;
            }
        }
    }

    .flip-all-btn {
        color: $blue;
        cursor: pointer;
        text-align: right;

        .flip-all-icon {
            vertical-align: middle;
        }
    }

    .parties-content {
        display: none;
        position: relative;
    }

    .parties-list {
        background: white;
        border-radius: 5px;
        border: 1px solid $light-gray;
        height: 120px;
        list-style-type: none;
        margin: 2px auto 5px;
        overflow-y: auto;
        padding: 8px 10px;
        text-align: left;
    }

    .parties-overlay {
        background: $blue;
        border-radius: 4px;
        height: 100%;
        opacity: .92;
        position: absolute;
        top: 0;
        width: 100%;
        cursor: pointer;
        animation: fadeIn;
        animation-duration: .2s;

        .overlay-content {
            color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            .overlay-add-icon {
                display: block;
                font-size: 4em;
                margin: 0 auto;
            }

            .overlay-text {
                font-weight: bold;
            }
        }
    }

    .parties-subtitle {
        color: $gray;
        font-size: .9em;
        margin: 4px auto 3px;

        .building-icon {
            vertical-align: text-top;
            font-size: 1em;
        }
    }

    .parties-title {
        color: $dark-gray;
        font-size: 1.1em;
        font-weight: bold;
    }
}

@media only screen and (min-width: $screen-sm) {

    .parties-group {
        padding: 12px 25px 12px;

        &.collapsible {
            bottom: 0;
            opacity: .5;
            width: calc(100% - 50px);
            margin-top: 3px;
            background-color: $lighter-gray;

            &.expanded {
                background-color: $light-orange;
                box-shadow: 3px 1px 4px $light-med-gray;
                position: absolute;
                opacity: 1;

                .parties-content {
                    display: block;
                }

                &:hover {
                    background-color: $light-orange;
                }
            }

            &:hover {
                opacity: 1;
                background-color: $light-orange;
            }

            .parties-content {
                display: none;
            }

        }

        &.compact {

            .parties-list {
                height: 107px;
            }
        }

        &.counter-parties {

            .flip-all-btn {
                display: none;
            }
        }

        &.hide-on-mobile {
            display: block;
        }

        .collapse-toggle {
            display: none;
        }

        .flip-all-btn {
            bottom: -27px;
            position: absolute;
            top: auto;
            right: 0;
            z-index: 1;
        }

        .parties-content {
            display: block;
        }
    }
}
