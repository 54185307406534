.modal-dialog {
    background: rgba(0, 0, 0, .36);

    .modal-dialog-window {
        background: white;
        border: 1px solid $light-gray-alt;
        border-radius: 4px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, .4);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        left: 50%;
        max-height: 90%;
        overflow: auto;
        padding: 24px 28px 34px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            height: 90%;
        }

        .modal-dialog-window-header {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 35px;

            .modal-dialog-title {
                color: $gray-black;
                font-size: 1.25em;
                font-weight: bold;

                .modal-title-wrapper {
                    font-size: .9em;

                    .title-icon,
                    .title-text {
                        vertical-align: middle;
                    }

                    .subtitle {
                        color: $medium-dark-gray;
                        font-size: $small-label-font-size;
                        font-weight: normal;
                        margin-top: 6px;
                    }
                }
            }

            .modal-dialog-close-button {
                color: $blue;
                cursor: pointer;
                margin-left: 16px;
            }
        }

        .modal-dialog-window-content {
            flex: 1;
        }

        .modal-dialog-window-footer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 35px;

            button {
                margin-left: 16px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}
