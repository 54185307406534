.app-container {
    height: 100%;
    position: relative;

    .app {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: left;
    }

    .header {
        align-items: center;
        background-color: $dark-blue;
        color: white;
        display: flex;
        flex-direction: row;
        font-size: 1.5em;
        justify-content: space-between;
        padding: 18px 25px;

        .logo-img {
            height: auto;
            max-height: 45px;
            max-width: 150px;
            width: auto;
        }

        .page-title {
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 165px;
        }

        &.sub-header {
            background-color: $blue;
            font-size: 1.1em;
            text-align: left;

            .sub-header-text {

                &.right {
                    display: none;
                    text-align: right;
                }
            }
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .splash-content {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);
        text-align: center;
        max-width: 500px;
        color: $dark-gray;

        .loader-wrapper {
            color: $blue;
            text-align: center;
            margin-top: 12px;
            font-size: 1.2em;

            > * {
                vertical-align: middle;
            }

            .loader {
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }
        }

        .message {
            margin-top: 15px;
            font-size: 1.5em;
            line-height: 1.5em;
        }
    }

}

@media only screen and (min-width: $screen-xs) {

    .header {

        .logo-img {
            max-width: 200px;
        }

        .page-title {
            width: 250px;
        }
    }
}

@media only screen and (min-width: $screen-sm) {

    .app {

        .header {

            .page-title {
                width: 500px;
            }

            &.sub-header {
                font-size: 1.5em;

                .sub-header-text {

                    &.right {
                        display: block;
                        min-width: 30%;
                        max-width: 450px;
                    }
                }
            }
        }

        .main {
            flex-direction: row;
        }
    }
}

