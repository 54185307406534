.Toastify__toast-container {
    font-size: 1.2em;

    .Toastify__toast--warning {
        background: $orange;
    }

    .Toastify__toast-body {
        padding-left: 10px;
    }
}

.mui-tooltip {
    background-color: white !important;
    color: $gray !important;
    border: 1px solid $light-gray;
    border-radius: 0 !important;
}
