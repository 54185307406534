.paragraph {
    line-height: 1.4em;

    .party-name {
        color: $link-blue;
        cursor: pointer;
        border-radius: 10px;
        padding: 1px 3px;
        margin-left: -3px;
        margin-right: -3px;

        &.discarded {
            color: $orange;

            &.highlighted {
                background-color: $light-orange;
            }
        }

        &.highlighted {
            background-color: $light-blue;
            z-index: 1;
        }

        &.selected {
            background-color: $blue;
            color: white;
            user-select: none;

            &.discarded {
                background-color: $orange;
            }

            .party-name {
                color: white;

                &.highlighted {
                    color: $dark-gray;
                }
            }
        }

        &+ .party-name,
        &+ .party-to-add {
            margin-left: 4px;
        }
    }

    .party-to-add {
        background-color: $highlight;
        color: $dark-gray;
        padding-bottom: 1px;
        padding-top: 1px;

        &+ .party-name {
            margin-left: .7px;
        }
    }
}
