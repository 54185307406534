.login-form {
    box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
    margin: 0 auto;
    width: 330px;
    padding: 30px;
    margin-top: 15px;

    .form-control {
        margin-bottom: 15px;

        &:focus {
            outline-color: $blue;
        }
    }

    .message {
        margin: 15px auto;
        font-size: 1.1em;
    }
}
