.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
    display: inline-block;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: .2em solid rgba(200, 200, 200, 0.2);
    border-right: .2em solid rgba(200, 200, 200, 0.2);
    border-bottom: .2em solid rgba(200, 200, 200, 0.2);
    border-left: .2em solid $blue;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loader 1.1s infinite linear;
    animation: loader 1.1s infinite linear;
}

.ellipsis {
    display: inline-block;
    min-width: 1.25em;
    text-align: left;
}

.animated-ellipsis {

    &:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ellipsis steps(4,end) 1200ms infinite;
        content: "\2026";
        width: 0px;
    }
}
