.modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &.visible {
        opacity: 1;
        z-index: 2000;

        transition: z-index, opacity;
        transition-duration: 0ms, $time-normal;
        transition-timing-function: ease, ease;
        transition-delay: 0ms, 0ms;
    }

    &.hidden {
        opacity: 0;
        z-index: -1001;

        transition: opacity, z-index;
        transition-duration: $time-normal, 0ms;
        transition-timing-function: ease, ease;
        transition-delay: 0ms, $time-normal;
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}
