$blue: #5098fd;
$dark-blue: #253441;
$dark-gray: #4a5460;
$gray: #6f7780;
$dark-gray: #747474;
$gray-black: #2d2d2d;
$medium-dark-gray: #a9a9a9;
$highlight: #fdfd9d;
$light-blue: #dcebfe;
$light-gray: #cdcdcd;
$light-gray-alt: #e6e6e6;
$light-med-gray: #b3b3b3;
$light-orange: #fef4e8;
$lighter-gray: #e9e9e9;
$lightest-gray: #f2f2f2;
$link-blue: #5ca8de;
$med-gray: #575f6a;
$orange: #f7931d;

$screen-md: 930px;
$screen-sm: 768px;
$screen-xs: 500px;

$small-label-font-size: 0.8em;
$time-normal: 250ms;