.form-control {
    border-radius: 4px;
    padding: 10px 12px;
    border: none;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $light-gray;

    &.select {
        display: block;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0;
        border-radius: 4px;
        appearance: none;
        background-color: white;
    }
}

.control-label {
    font-size: .85em;
    margin: 0 0 2px 2px;
    display: inline-block;
    color: $gray;
    display: block;
    text-align: left;
}
