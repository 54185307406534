@font-face {
    font-family: 'Lato';
    src: url('../assets/fonts/Lato-Bold.eot'),
        url('../assets/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-Bold.woff2') format('woff2'),
        url('../assets/fonts/Lato-Bold.woff') format('woff'),
        url('../assets/fonts/Lato-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../assets/fonts/Lato-Bold.eot'),
        url('../assets/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-Bold.woff2') format('woff2'),
        url('../assets/fonts/Lato-Bold.woff') format('woff'),
        url('../assets/fonts/Lato-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../assets/fonts/Lato-BoldItalic.eot'),
        url('../assets/fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Lato-BoldItalic.woff') format('woff'),
        url('../assets/fonts/Lato-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../assets/fonts/Lato-BoldItalic.eot'),
        url('../assets/fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Lato-BoldItalic.woff') format('woff'),
        url('../assets/fonts/Lato-BoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../assets/fonts/Lato-Regular.eot'),
        url('../assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-Regular.woff2') format('woff2'),
        url('../assets/fonts/Lato-Regular.woff') format('woff'),
        url('../assets/fonts/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../assets/fonts/Lato-Regular.eot'),
        url('../assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-Regular.woff2') format('woff2'),
        url('../assets/fonts/Lato-Regular.woff') format('woff'),
        url('../assets/fonts/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../assets/fonts/Lato-RegularItalic.eot'),
        url('../assets/fonts/Lato-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-RegularItalic.woff2') format('woff2'),
        url('../assets/fonts/Lato-RegularItalic.woff') format('woff'),
        url('../assets/fonts/Lato-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../assets/fonts/Lato-RegularItalic.eot'),
        url('../assets/fonts/Lato-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-RegularItalic.woff2') format('woff2'),
        url('../assets/fonts/Lato-RegularItalic.woff') format('woff'),
        url('../assets/fonts/Lato-RegularItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
