.btn {
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
    outline: none;
    padding: 6px 14px;
}

.btn-primary {
    background: $blue;
    color: white;
}

.btn-block {
    width: 100%;
}
