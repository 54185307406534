
.contract {
    background: white;
    display: flex;
    flex-direction: column;
    order: 2;
    padding: 25px;

    .caption {
        color: $gray;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        text-align: left;

        > * {
            align-self: center;
        }

        .caption-icon {
            color: $light-med-gray;
            font-size: 1.3em;
        }

        .building-icon {
            vertical-align: bottom;
        }
    }

    .contract-body {
        background: white;
        border-radius: 4px;
        border: 1px solid $lighter-gray;
        flex: 1 1 auto;
        height: 500px;
        overflow-y: auto;
        padding: 30px;
        text-align: left;

        .paragraphs {
            color: $dark-gray;
            list-style-type: none;
            margin: 0;
            padding: 0;

            .paragraph {
                margin-bottom: 20px;
            }
        }
    }
}

@media only screen and (min-width: $screen-sm) {

    .contract {
        box-sizing: border-box;
        flex-grow: 1;
        order: 1;
        max-width: 70%;
        width: auto;

        .contract-body {
            height: 0;
        }
    }
}
